import React from 'react'
import { useTranslations } from '../../context/TranslationsContext'
import { getPokemonName } from '../../utils/pokemon'
import { Badge, Typography } from '../Atoms'

export const PathItem = ({ path }) => {
    const pokemonName = getPokemonName(path.monster_id)
    const { t } = useTranslations()
    return (
        <div className="d-flex flex-column align-items-center justify-content-center flex-shrink-0" style={{ gap: '.5rem' }}>
            <img src={"https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/" + path.monster_id + ".png"} alt={pokemonName} width="80" height="80"></img>
            <Typography className="text-muted mb-0 text-center">{pokemonName}</Typography>
            <Badge pill>
                {
                    path.level === 106
                        ? t('Evolve')
                        : path.level === 108
                            ? t("Breed")
                            : path.level === 101
                                ? t("Special")
                                : `lv. ${path.level}`
                }
            </Badge>
        </div>
    )
}
